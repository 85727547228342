<template>
    <div>
        <div class="services page-wrapper" v-if="dataLoaded">
            <div
                class="d-flex flex-column justify-content-center position-relative"
            >
                <div
                    class="container-longer container-fluid text-white text-center"
                >
                    <div class="row py-7 pb-xl-8">
                        <h1 class="col-12">
                            {{ object.title }}
                        </h1>
                        <div
                            class="col-12 text-bigger"
                            v-html="object.description"
                        ></div>
                        <Button url="contact" bgColor="pink" class="mx-auto"
                            >Get In Touch</Button
                        >
                    </div>
                </div>
            </div>
            <template v-if="object.work && object.work.length">
                <div
                    class="bg-secondary d-flex align-items-center position-relative py-4"
                >
                    <div class="container-fluid text-center">
                        <h5
                            class="d-flex align-items-center justify-content-center mb-0 text-uppercase font-weight-bold"
                        >
                            Featured work
                        </h5>
                    </div>
                </div>
                <div class="projects-list">
                    <Projects :projects="object.work" />
                </div>
            </template>
            <template v-else>
                <p class="text text-center my-5">
                    Currently there are no projects to show
                </p>
            </template>
        </div>
        <Footer />
    </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import seoMixin from "@/mixins/seoMixin";
import Projects from "../components/Projects.vue";
import Button from "../components/Button.vue";
import Footer from "../components/Footer.vue";

export default {
    name: "services",
    mixins: [seoMixin],
    components: {
        Projects,
        Button,
        Footer
    },
    data() {
        return {
            object: {},
            dataLoaded: false
        };
    },
    computed: {
        ...mapGetters(["navState"])
    },
    methods: {
        goToPage(e, slug) {
            e.preventDefault();
            if ($("html").hasClass("touch")) {
                e.preventDefault();
                setTimeout(() => {
                    this.$router.push({
                        name: "work-detail",
                        params: { slug: slug }
                    });
                }, 1000); // čekamo prije prelaska na stranicu kratko vrijeme da se ispiše ime projekta na koji se kliknulo
            } else {
                this.$router.push({
                    name: "work-detail",
                    params: { slug: slug }
                });
            }
        },
        stickyCallback(obj) {
            if (obj.sticked) {
                document.querySelector(".sticky-nav").classList.add("fixed");
            } else {
                document.querySelector(".sticky-nav").classList.remove("fixed");
                document
                    .querySelector(".sticky-nav")
                    .classList.add("position-relative");
            }
        }
    },
    created() {
        const slug = this.$route.params.slug;
        const pageApi = `wp-json/wp/v2/service?slug=${slug}`;
        const data = axios
            .get(pageApi)
            .then(res => {
                this.object = res.data[0];
                // from seoMixin
                this.setSeo(
                    this.object.title,
                    this.object.description,
                    "https://www.wearealive.com/WAA_default.jpeg",
                    "https://www.wearealive.com" + this.$route.fullPath
                );
                this.dataLoaded = true;
                document.dispatchEvent(new Event("custom-render-trigger"));

                setTimeout(() => {
                    $("body").css("visibility", "unset");
                    this.$store.dispatch("setLoading", false);
                }, loadingDelay);
            })
            .catch(e => {
                this.$router.push("/404");
            });
    }
};
</script>

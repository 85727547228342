<template>
    <div class="row no-gutters">
        <router-link
            class="project-wrapper position-relative col-12 col-sm-6 col-lg-4 col-xl-3 bg-secondary zoom-on-hover"
            v-for="(project, id) in projects"
            :key="id"
            :to="`/work/${project.slug}`"
        >
            <img
                v-lazy="
                    project.acf
                        ? project.acf.list_image.image.sizes.large
                        : project.list_image.image.sizes.large
                "
                :alt="
                    project.acf
                        ? project.acf.list_image.image_title
                        : project.list_image.image_title
                "
                class="img-fluid w-100"
            />
            <div class="project-info text-uppercase">
                <span class="project-inner-text w-100 px-4 pb-4">
                    <h3 class="text-white font-weight-bolder">
                        <span
                            >{{
                                project.acf
                                    ? project.acf.client_name
                                    : project.client_name
                            }}:</span
                        >
                        <span>
                            {{
                                project.acf
                                    ? project.acf.project_name
                                    : project.project_name
                            }}
                        </span>
                    </h3>
                </span>
            </div>
        </router-link>
        <router-link
            to="/work"
            class="project-wrapper see-more-box text-white position-relative cursor-pointer col-12 col-sm-6 col-lg-4 col-xl-3"
            v-if="seeMore"
        >
            <div class="inner-content">
                <h2 class="inner-text">
                    Want to
                    <br />see even <br />more?
                </h2>
                <img
                    class="arrow-right mt-2"
                    src="../assets/img/arrow-right.svg"
                    alt="Arrow right"
                />
            </div>
        </router-link>
    </div>
</template>

<script>
export default {
    props: {
        projects: {
            type: Array,
            default: () => []
        },
        seeMore: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style lang="scss" scoped></style>
